import * as React from "react";
import { graphql } from "gatsby";
import { inject } from "mobx-react";

import { PAGE_VIEW_EVENT, NAVIGATE_EVENT, TraumwandPageComponent, getLanguageValue, getFluidImageSharp } from "utils";
import { Seo, ProductPageCollection } from "components";
import { SquidexProductOverview, SquidexProductPageConnection } from 'generated/gatsbyApiTypes';

export interface QueryData
{
  squidexProductOverview: SquidexProductOverview;
  productPages: SquidexProductPageConnection;
}

@inject("visitorStore")
export default class ProductOverviewPage extends TraumwandPageComponent<QueryData> {
  componentWillMount() {
    this.props.visitorStore.logEvent(PAGE_VIEW_EVENT, {
      location: this.props.location,
      pageType: "product-overview"
    });
  }
  render() {
    const { squidexProductOverview, productPages } = this.props.data;
    const intro = getLanguageValue(squidexProductOverview.introText).html;
    const header = squidexProductOverview.introImage
      ? getFluidImageSharp(squidexProductOverview.introImage)
      : undefined;
    return (
      <>
        <Seo subTitle={getLanguageValue(squidexProductOverview.title)}/>
        <div className="mb4 flex relative">
          <div className="flex items-center flex-column">
            <div className="mr2" dangerouslySetInnerHTML={{ __html: intro }} />
          </div>
          {/* {header ? <Img fluid={header} className="w-50" /> : undefined} */}
        </div>
        <ProductPageCollection nodes={productPages.nodes} btnText={getLanguageValue(squidexProductOverview.txtOverviewItemLink)} navigate={this.navigate} />
      </>
    )
  }
  
  navigate = (link: string) => {
    if (!!link) {
      this.props.navigate(link);
      this.props.visitorStore.logEvent(NAVIGATE_EVENT, {
        location: this.props.location,
        href: link
      });
    }
  }
}

export const query = graphql`
query($id: String!) {
  squidexProductOverview(id: {eq: $id}) {
    title {
      de
      nl
      en
    }
    introText {
      de {
        html
      }
      nl {
        html
      }
      en {
        html
      }
    }
    introImage {
      childImageSharp {
        fluid(maxWidth: 1600) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    txtOverviewItemLink {
      de
      nl
      en
    }
  }
  productPages: allSquidexProductPage(sort: {order:DESC, fields: priority___iv}) {
    nodes {
      title {
        de
        nl
        en 
      }
      slug {
        iv
      }
      headerImage {
        childImageSharp {
          fluid(maxWidth: 600) { 
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      tagline {
        de
        nl
        en
      }
      qualities {
        de {
          text
          positive
        }
        nl {
          text
          positive
        }
        en {
          text
          positive
        }
      }
    }
  }
}
`
